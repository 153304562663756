import WcCardTabs from '@components/shared/WcCardTabs'

const FirmwareTabs = {
  components: {
    WcCardTabs,
  },

  computed: {
    tabs() {
      return [
        {
          name: this.$t('activerecord.models.firmware.one'),
          route: { name: 'manager-firmwares-edit', params: { id: this.firmware_id } },
        },
      ]
    },
  },
}

export default FirmwareTabs
